import React from "react"
import { Link } from "gatsby"
import user_icon from "../../images/user_icon.svg"
import tele_icon from "../../images/add-user.svg"
import "./menu.css"
import { StaticImage } from "gatsby-plugin-image"

import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap"

const HorizontalMenu = ({ location }) => {
  return (
    <>
      <div className="hmenu-wrapper">
        {/* <div className="container"> */}
        <div className="hmenu-items-flex justify-content-center">
        
          <DropdownButton
            id="dropdown-item-button-1"
            title="Our Firm"
            className="hmenu-button"
          >
            <div
              className="d-flex flex-column"
              style={{ width: "max-content" }}
            >
              <Link to="/our-team/">Our Team</Link>

              <Link to="/careers/">Careers</Link>
              {/* <Link to="/partnership-opportunities/">
                Partnership Opportunities
              </Link> */}
            </div>
          </DropdownButton>
          <DropdownButton
            id="dropdown-item-button-2"
            title="What We Do"
            className="hmenu-button"
          >
            <div
              className="d-flex flex-column"
              style={{ width: "max-content" }}
            >
              <Link to="/multi-family-office/">Multi-Family Office</Link>
              <Link to="/institutional-investors/">Institutional</Link>
              <Link to="/asset-management/">Asset Management</Link>
              <Link to="/financial-planning/">Financial Planning</Link>
              {/* {location === "/lp-program/" && <Link to="/">Education</Link>} */}
              <Link to="/lp-program/">Education</Link>

              <Link
                className="text-uppercase v-all-service text-center"
                to="/services/"
              >
                <span>View All Services</span>
              </Link>
            </div>
          </DropdownButton>{" "}
          <button className="hmenu-button link-as-button">
            <Link to="/real-estate/" className="hmenu-button px-2">
              Real Estate
            </Link>
          </button>
          <button className="hmenu-button link-as-button">
            <Link to="/press-and-publications/" className="hmenu-button px-2">
              Media Coverage
            </Link>
          </button>
          <button className="hmenu-button link-as-button">
            <Link to="/testimonials/" className="hmenu-button px-2">
              Testimonials
            </Link>
          </button>
          <DropdownButton
            id="dropdown-item-button-3"
            title="Insights"
            className="hmenu-button"
          >
            <div
              className="d-flex flex-column"
              style={{ width: "max-content" }}
            >
              <Link to="/blog/">Articles</Link>
              <Link to="/howd-you-do-it-podcast/">Podcast</Link>
            </div>
          </DropdownButton>
        </div>
        {/* </div> */}
      </div>
      <div className="account-field mt-5 d-lg-none d-md-none d-block">
        <p>
          <Link to="/client-login/" className="my_btn_login w-100 text-center">
            <StaticImage
              src="../../images/user_icon.svg"
              alt="user icon"
              className="mr-1"
              loading="lazy"
            />
            LOG IN
          </Link>
        </p>
      </div>
    </>
  )
}

export default HorizontalMenu
