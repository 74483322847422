import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import { stickyWrapper, isSticky, header } from "./header.module.css"
import HorizontalMenu from "../sidebar/horizontalmenu"
import { StaticImage } from "gatsby-plugin-image"
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap"
import { useLocation } from "@reach/router"

const Header = () => {
  const location = useLocation()
  console.log("location", location)
  return (
    <header>
      <Sticky
        className={stickyWrapper}
        stickyClassName={isSticky}
        stickyStyle={{ transform: "unset", zIndex: "2" }}
      >
        <div className={`header ${header} box-shadow`}>
          <div className="container">
            <div className="row my-1 top-menu d-lg-flex d-md-flex d-none">
              <div className="col-md-4">
                <DropdownButton
                  id="dropdown-item-button"
                  title="Portfolio Companies"
                  className="hmenu-button mb-3"
                >
                  <div className="dropdown-item d-flex justify-content-left">
                    <a
                      href="https://www.resthavenproperties.com/"
                      target="_blank"
                    >
                      Resthaven Properties
                    </a>
                  </div>
                  <div className="dropdown-item d-flex justify-content-left">
                    <a
                      href="https://www.Resthavenlosangeles.com/"
                      target="_blank"
                    >
                      Resthaven Los Angeles
                    </a>
                  </div>
                  <div className="dropdown-item d-flex justify-content-left">
                    <a href="https://www.Resthaventahoe.com" target="_blank">
                      Resthaven Tahoe
                    </a>
                  </div>
                  <div className="dropdown-item d-flex justify-content-left">
                    <a target="_blank">Unified Hub</a>
                  </div>
                </DropdownButton>
              </div>
              <div className="col-md-8 d-none d-lg-inline d-md-none">
                <div className="h-top-menu align-items-center d-flex justify-content-end border-bottom pb-2">
                  <Link to="/client-login/">
                    <svg
                      width="18"
                      height="19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        d="M9 0a5 5 0 1 0 0 10A5 5 0 0 0 9 0Zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm9 11v-1a7 7 0 0 0-7-7H7a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1h2Z"
                        fill="#323232"
                      />
                    </svg>
                    Investor Login
                  </Link>
                </div>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <div className="header-logo">
                  <Link aria-label="go to homepage" to="/">
                    <span className="display-4 text-white">
                      <StaticImage
                        className="my_logo_img"
                        src="../../images/BSCH_Primary_Logo_white-01.png"
                        width="300"
                        height="110"
                        placeholder="dominantColor"
                        aspectRatio={4 / 3}
                        alt="logo"
                        loading="eager"
                      />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-auto d-none d-lg-flex align-items-center">
                <HorizontalMenu location={location.pathname} />
              </div>

              <div className="col-auto d-inline d-lg-none pl-0">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
