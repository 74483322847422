import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Logo from "../../images/BAYSTREET-LOGO-WHITE.png"
import WebriQForm from "@webriq/gatsby-webriq-form"


const footer = ({ withContactSection = true }) => {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-4">
              <div className="mb-sm-2 d-flex align-items-center">
                <StaticImage
                  src="../../images/BAYSTREET-LOGO-WHITE.png"
                  width="209"
                  height="62"
                  alt="Logo"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-8 mb-5 mt-3 mt-lg-0 mt-md-0">
              <div class="d-md-flex justify-content-between mx-auto col-md-12">
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-2 px-2">
                  <div className="mt-3 d-flex flex-column mb-2 mb-lg-0 mb-md-0">
                    <Link to="/our-team/">
                      <p className="footer-heading">About</p>
                    </Link>

                    <Link to="/testimonials/">
                      <p className="footer-heading my-md-1 pt-3">
                        Testimonials
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-2 px-2">
                  <div className="mt-3 d-flex flex-column mb-2 mb-lg-0 mb-md-0">
                    <Link to="/services/">
                      <p className="footer-heading">Our Services</p>
                    </Link>
                    <Link to="/real-estate/">
                      <p className="footer-heading my-md-1 pt-3">Real Estate</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-2 px-2">
                  <div className="mt-3 d-flex flex-column mb-2 mb-lg-0 mb-md-0">
                    <Link to="/press-and-publications/">
                      <p className="footer-heading">Media Coverage</p>
                    </Link>
                    <Link to="/blog/">
                      <p className="footer-heading my-md-1 pt-3">Insights</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-xsm-5 mt-sm-3 mb-sm-3 mt-md-0 mt-lg-0 mb-5">
              <div className="mb-sm-5 d-grid align-items-center px-2 mt-3 mt-lg-0 mt-md-0">
                <p className="footer-heading my-md-1 flex-column">Follow Us</p>

                <div className="text-left mt-md-5 mt-sm-2 mt-lg-5">
                  <a
                    aria-label="go to youtube iframe"
                    href="https://www.youtube.com/channel/UCrW--z73v5yTiqbrw8QyfkA"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <StaticImage
                      className="soc_icons"
                      src="../../images/youtube.png"
                      alt="youtube"
                      loading="lazy"
                    />
                  </a>
                  <a
                    aria-label="go to instagram baystreet"
                    href="https://www.instagram.com/baystreetcapitalholdings/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <StaticImage
                      className="soc_icons"
                      src="../../images/instagram.png"
                      alt="instagram"
                      loading="lazy"
                    />
                  </a>

                  <a
                    aria-label="go to facebook baystreet"
                    href="https://www.facebook.com/baystreetcapitalholdings"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <StaticImage
                      className="soc_icons"
                      src="../../images/facebook.png"
                      alt="facebook"
                      loading="lazy"
                    />
                  </a>
                  <a
                    aria-label="go to twitter baystreet"
                    href="https://twitter.com/BayStreetSpeaks"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <StaticImage
                      className="soc_icons"
                      src="../../images/twitter.png"
                      alt="twitter"
                      loading="lazy"
                    />
                  </a>
                  <a
                    aria-label="go to linkedin baystreet"
                    href="https://www.linkedin.com/company/bay-street-capital-holdings/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <StaticImage
                      className="soc_icons"
                      src="../../images/linkedin.png"
                      alt="linkedin"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <hr
                className="border-5 border-white pt-5 d-none d-lg-block"
                style={{
                  height: "10px",
                  borderColor: "#fff",
                  opacity: "100%",
                  borderWidth: "3px",
                }}
              ></hr>
              <div class="d-md-flex justify-content-between mx-auto col-md-12">
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-5 mb-5 mb-lg-5 px-2">
                  <span className=" footer-heading my-md-1">Bay Area</span>
                  <div className="mt-3 d-flex flex-column">
                    <a
                      aria-label="go to Tom Blalock St. location map"
                      href="https://www.google.com/maps/place/45349+Tom+Blalock+St+%23102,+Fremont,+CA+94539,+USA/data=!4m2!3m1!1s0x808fc6f6e4653975:0xda45ab932c93268a?sa=X&ved=2ahUKEwiF4qD4rZH-AhW99zgGHWvWCm8Q8gF6BAgNEAI"
                      className="footerlinks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <p>45349 Tom Blalock St., #102, Fremont, CA 94539</p> */}
                      <p>Tom Blalock St.</p>
                      <p>Fremont, California </p>
                    </a>
                    <a
                      aria-label="on click call telephone number / mobile"
                      href="tel:650-446-5544"
                      className="footerlinks mt-3"
                      rel="noopener noreferrer"
                    >
                      {/* <p>510-858-1921</p> */}
                      <p>650-446-5544</p>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-5 mb-5 mb-lg-5 px-2">
                  <span className=" footer-heading my-md-1">Los Angeles</span>
                  <div className="mt-3 d-flex flex-column">
                    <a
                      aria-label="go to 524 Rialto Ave location map"
                      href="https://www.google.com/maps?q=524+Rialto+Ave,+Venice+CA,+90291&rlz=1C1CHBF_enPH1039PH1039&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiNsZ__1pH-AhWaGYgKHXt_CUEQ_AUoAXoECAIQAw"
                      className="footerlinks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <p>524 Rialto Ave, Venice CA, 90291</p> */}
                      <p>524 Rialto Ave</p>
                      <p> Venice, California</p>
                    </a>
                    <a
                      aria-label=""
                      href="tel:510-858-1921"
                      className="footerlinks mt-3"
                      rel="noopener noreferrer"
                    >
                      <p>510-858-1921</p>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-baseline flex-column mb-md-5 mb-5 mb-lg-5 px-2">
                  <span className=" footer-heading my-md-1">New York</span>
                  <div className="mt-3 d-flex flex-column">
                    <a
                      aria-label="go to 14 wall Street location map"
                      href="https://www.google.com/maps?q=14+Wall+Street+20th+Floor+New+York,+NY+10005&rlz=1C1CHBF_enPH1039PH1039&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjUm6mR15H-AhXYmFYBHUMQD1AQ_AUoAXoECAEQAw"
                      className="footerlinks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <p>14 Wall Street 20th Floor New York, NY 10005</p> */}
                      <p>14 Wall Street</p>
                      <p>New York City, New York</p>
                      {/* <p>New York 14 Wall Street New York City, New York</p> */}
                    </a>
                    <a
                      aria-label="on click go to call telephone/mobile"
                      href="tel:917-740-0403"
                      className="footerlinks mt-3"
                      rel="noopener noreferrer"
                    >
                      {/* <p>510-858-1921</p> */}
                      <p>917-740-0403</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-md-6">
              <div className="d-flex flex-row">
                <div className="p-2">
                  <ul className="list-unstyled mt-2 d-md-flex mb-0 mx-auto justify-content-md-center text-white">
                    <li>
                      <Link
                        aria-label="go to disclosures disclaimers"
                        to="/disclosures-disclaimers/"
                        style={{ color: "#fff" }}
                        className="disclosure-link"
                      >
                        Disclosures & Disclaimers
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 mx-2 mx-lg-0 mx-md-0">
              <ul className="list-unstyled mt-2 d-md-flex mb-0 mx-auto justify-content-md-center text-white">
                <li>
                  Copyright © {new Date().getFullYear()} Bay Street Capital
                  Holdings. All rights reserved.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
