import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import Footer from "./footer/footer"
// import "./layout.css"
import "./my_global.scss"
import "./layout.scss"

import "../sass/main.scss"

import "bootstrap/dist/css/bootstrap.css"

import "font-awesome/css/font-awesome.min.css"

// import "../components/font-face.css"

import "../components/responsive-homepage.css"
import "../sass/pages/footer.scss"

import "../sass/main.scss"
import { useLocation } from "@reach/router"

import { LazyLoadComponent } from "react-lazy-load-image-component"

let noContactFooter = [
  "/real-estate/",
  "/real-estate",
  "/ila-corcoran/",
  "/ila-corcoran",
  "/services",
  "/services/",
  "/multi-family-office/",
  "multi-family-office",
  "/institutional-investors/",
  "/institutional-investors",
  "/asset-management/",
  "/asset-management",
  "/",
  "/financial-planning/",
  "/financial-planning",
]

const Layout = ({ children, bioPathName }) => {
  useEffect(() => {
    noContactFooter.push(bioPathName)
  }, [bioPathName])
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // When embedded in an iFrame, sends a postMessage "WEBRIQ_SITE_HAS_LOADED" to parent

  const [hasLoaded, setHasLoaded] = React.useState(false)
  React.useEffect(() => {
    const sendPostMessage = (e) => {
      if (!hasLoaded) {
        console.log("Sending postMessage: WEBRIQ_SITE_HAS_LOADED")
        window.parent.postMessage("WEBRIQ_SITE_HAS_LOADED", "*")
        console.log("Successfully sent postMessage: WEBRIQ_SITE_HAS_LOADED")
        setHasLoaded(true)
      }
    }

    sendPostMessage()
  }, [hasLoaded])

  const location = useLocation()

  // console.log("locationTestBIO", bioPathName)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
      {/* <Footer /> */}
      
      <Footer />
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
